<template>
  <div class="container">
    <h1>
      Import <span v-if="itemsToImport">({{ itemsToImport.length }})</span>
    </h1>
    <textarea
      type="text"
      class="search"
      placeholder="past data"
      v-model="importingData"
    />
    <button @click="convertIportingData" class="btn">import</button>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { db, serverTimestamp, Timestamp, fromDate } from "../firebase/config";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  // getDoc,
  // doc,
  // updateDoc,
} from "firebase/firestore";
import { watchEffect } from "@vue/runtime-core";
export default {
  name: "Home",
  setup() {
    const databaseName = ref("camsTEMP"); // TEMP

    const importingData = ref(null);
    const dataArr = ref([]);
    const dataObj = ref([]);
    const itemsToImport = ref(null);

    const convertIportingData = () => {
      // itemsToImport.value = 0;
      // console.log(importingData.value);
      dataArr.value = importingData.value.split("\n");
      dataObj.value = dataArr.value.map((el) => {
        let tempArr = el.split("\t");
        tempArr[1] = tempArr[1].substring(1);
        console.log(tempArr);

        itemsToImport.value = Object.keys(dataObj.value);
        // console.log(itemsToImport.value.length);

        return tempArr;
      });

      console.log(dataObj.value);

      updateData(dataObj.value);
    };

    // dla kazdego nicku, zaimportuj dane ze stripchata i dodaj text twitta z danych wraz z data

    const handleSubmitData = async (data) => {
      const colRef = collection(db, databaseName.value);
      const objData = JSON.parse(JSON.stringify(data));
      await addDoc(colRef, { ...objData, createdAt: serverTimestamp() });
      console.log("✅ DONE handleSubmitData ");
    };

    const handleSearch = async (service, search) => {
      const q = query(
        collection(db, databaseName.value),
        where(service, "==", search)
      );

      const querySnapshot = await getDocs(q);
      let counter = 0;

      querySnapshot.forEach((doc) => {
        counter++;
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        // camDataTemp1.value = doc.data();
        // currentCamId.value = doc.id;
      });
      console.log("Counter: ", counter);
      return counter;
    };

    const updateData = async (arr) => {
      // arr.forEach(async (el) => {

      arr.forEach(async (el) => {
        // console.log(el);
        // console.log(el[0]);
        // console.log(el[1]);
        // console.log(el[2]);
        // console.log(el[3]);
        // console.log("__________");

        let number = await handleSearch("stripchat", el[1]);
        console.log(number);
        if (number == 0) {
          ////////////////

          let data = await getDataStripchatImport(el[1], el[2], el[3], el[0]);
          console.log(data);

          if (data) {
            // let elNew = { ...data, ...el };
            // console.log(elNew);
            handleSubmitData(data);
          } else {
            // console.log("———————————");
            // allNewUrls.value = [];
            // splittedUrls.value = [];
            return;
          }
        }
      });
      // }
      // });
    };

    const wait = function (seconds) {
      return new Promise(function (resolve) {
        setTimeout(resolve, seconds * 1000);
      });
    };

    function generateRandom(maxLimit = 11) {
      let rand = Math.random() * maxLimit;
      console.log(rand);
      rand = Math.floor(rand); // 99
      return rand;
    }

    const clearDomain = (url) => {
      if (url.startsWith("http") || url.startsWith("www")) {
        let domain = new URL(url);
        const pathname = domain.pathname;
        const domainName = domain.hostname;
        return `${domain.hostname}${domain.pathname}`;
      } else {
        // console.log("URL nie zaczyna sie od http OR www");
        // console.log(url);
        let urlTemp = "https://www." + url;
        // console.log(urlTemp);
        let domain = new URL(urlTemp);
        // let domain = new URL(url);
        const pathname = domain.pathname;
        const domainName = domain.hostname;
        return `${domain.hostname}${domain.pathname}`;
      }
    };

    const stripchatCamDataImport = {
      name: null,
      contestGender: null,
      broadcastGender: null,
      age: null,
    };

    const convertDateToTimestamp = (date) => {
      date = date.split(".");
      var newDate = new Date(date[2], date[1] - 1, date[0]);
      // console.log(newDate.getTime());
      return newDate;
    };

    const getDataStripchatImport = async (
      nickname,
      tweetDate,
      tweetText,
      status
    ) => {
      console.log(nickname);
      console.log(tweetDate);
      console.log(tweetText);

      let tweetDateConverted = convertDateToTimestamp(tweetDate);
      console.log(tweetDateConverted);

      await wait(generateRandom(90));
      const res = await fetch(
        `https://stripchat.com/api/front/v2/models/username/${nickname}/cam`
      );
      const data = await res.json();
      // console.log(data);

      // let isLive = data.user.user.isLive;
      let socialLinks = data.user.socialLinksData;
      // let username = data.user.user.username;
      let name = data.user.user.name;
      let contestGender = data.user.user.contestGender;
      let broadcastGender = data.user.user.broadcastGender;
      let age = data.user.user.age;

      const camData = Object.create(stripchatCamDataImport);
      camData.name = name;
      camData.stripchat = nickname;
      camData.contestGender = contestGender;
      camData.broadcastGender = broadcastGender;
      camData.age = age;
      camData.status = status;
      camData.lastTweet = {
        // createdAt: serverTimestamp(),
        tweetText: tweetText,
      };

      if (Object.keys(socialLinks).length > 0) {
        for (const [key, value] of Object.entries(socialLinks)) {
          // console.log(`🔷socialLinks ${key}: ${clearDomain(value.link)}`);
          camData[`${key}`] = clearDomain(value.link);
        }
      } else {
        console.log(`🛑 There is NO sociallinks (${nickname}) \n\n`);
        return null;
      }

      return camData;
    };

    return { importingData, convertIportingData, itemsToImport };
  },
};
</script>

<style scoped>
.container {
  padding: 35px;
}
button {
  /* height: 56px;
  width: 56px; */
  border-radius: 7px;
  border: 0px;
  background-color: rgb(0, 60, 255);
  font-size: 1rem;
  line-height: 56px;
  padding: 0px 20px;
  color: rgb(255, 255, 255);
  /* position: fixed;
  bottom: 35px;
  left: 35px; */
}

button:hover {
  background-color: rgb(0, 49, 209);

  cursor: pointer;
  transition-duration: 0.5s;
}
</style>
