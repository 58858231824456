import { initializeApp } from "firebase/app";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDKyG9s_8zoZ7Wed4W80Ggxsmf-SftJkVs",
  authDomain: "camscrapper.firebaseapp.com",
  projectId: "camscrapper",
  storageBucket: "camscrapper.appspot.com",
  messagingSenderId: "1096341464169",
  appId: "1:1096341464169:web:6d147e49d09040e74caef8",
};

// init firebase
initializeApp(firebaseConfig);

// init services
const db = getFirestore();
// const timestamp = serverTimestamp();

const auth = getAuth();

export { db, serverTimestamp, auth };
