<template>
  <div class="twitterBox" v-if="!isCamgirlPro">
    <div class="text">
      <span @click="getChoosenHi">{{ choosenHi }}&nbsp;</span>
      <span @click="changeName">{{ clearedChosenName }} &nbsp;</span>

      <span @click="getChoosenIconsAll"> {{ choosenIconsAll }}&nbsp;</span>
      <span @click="getChoosenTextTwitter('beginner')"
        >{{ choosenTextTwitter }} &nbsp;
      </span>

      <!-- <span
        ><br /><br /><br /><strong
          >databaseType: {{ databaseName }} <br />
          isCamgirlPro: {{ isCamgirlPro }}
        </strong></span
      ><br /> -->
    </div>
    <div class="buttons">
      <div class="refresh" @click="refreshAll">
        <span class="material-icons"> restart_alt </span>
      </div>
      <div
        class="refresh"
        @click="
          handleGo(
            cam.twitter,
            `${choosenHi} ${clearedChosenName} ${choosenIconsAll} ${choosenTextTwitter}`
          )
        "
      >
        <span class="material-icons"> thumb_up_alt </span>
      </div>
    </div>
  </div>
  <div class="twitterBox pro" v-if="isCamgirlPro">
    <div class="text">
      <span @click="getChoosenHi">{{ choosenHi }}&nbsp;</span>
      <span @click="changeName">{{ clearedChosenName }} &nbsp;</span>

      <span @click="getChoosenIconsAll"> {{ choosenIconsAll }}&nbsp;</span>
      <span @click="getChoosenTextTwitter('pro')"
        >{{ choosenTextTwitterPro }} &nbsp;
      </span>

      <!-- <span
        ><br /><br /><br /><strong
          >databaseType: {{ databaseName }} <br />
          isCamgirlPro: {{ isCamgirlPro }}
        </strong></span
      ><br /> -->
    </div>
    <div class="buttons">
      <div class="refresh" @click="refreshAll">
        <span class="material-icons"> restart_alt </span>
      </div>
      <div
        class="refresh"
        @click="
          handleGo(
            cam.twitter,
            `${choosenHi} ${clearedChosenName} ${choosenIconsAll} ${choosenTextTwitterPro}`
          )
        "
      >
        <span class="material-icons"> thumb_up_alt </span>
      </div>
    </div>
  </div>

  <div class="row" v-if="cam.lastTweet && isNotTweetRemoved">
    <div class="tweetText">
      <span v-if="cam.lastTweet.createdAt">
        {{ parseDate(cam.lastTweet.createdAt.seconds) }}
        ago
      </span>

      <span v-else>⚠️IMPROTED</span>

      <br />{{ cam.lastTweet.tweetText }}
    </div>
    <div class="refresh" @click="removeTweet(camId)">
      <span class="material-icons"> delete_forever </span>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { db, serverTimestamp } from "../firebase/config";
import { formatDistanceToNow, parse } from "date-fns";

// import { parseDate } from "../views/Home.vue";

import { doc, updateDoc, deleteField } from "firebase/firestore";
import { watchEffect } from "@vue/runtime-core";

export default {
  props: ["camData", "camId", "databaseType", "isCamgirlPro"],
  setup(props) {
    const databaseName = ref("");
    databaseName.value = props.databaseType;

    // const databaseName = ref("camsTEMP");
    // const databaseType = ref("");
    // databaseType.value = props.databaseType;

    const cam = ref(null);
    cam.value = props.camData[0];

    const camId = ref(null);
    camId.value = props.camId;

    const isCamgirlPro = ref(null);
    isCamgirlPro.value = props.isCamgirlPro;

    const isNotTweetRemoved = ref(true);
    const clearedNames = ref([]);
    const clearedChosenName = ref();

    const counter = ref(0);

    const hi = ref(["Hi", "WOW", "Holly fcuk", "Ciao", "Holly F***"]);
    const iconsAll = ref([
      "🔥🔥🔥",
      "💋💋💋",
      "🖤🖤🖤",
      "💗💗💗",
      "💜💜💜",
      "💛🧡❤️",
      "💗💗💗",
      "🍑🍑🍑",
      "🐱",
      "💖",
      "🍭🍭🍭",
      "✨✨✨",
      "💣",
    ]);

    const textTwitter = ref([
      "Did you hear about https://bit.ly/high-payout ?",
      "Do you stream also on other cam sites?  Did you hear about https://bit.ly/high-payout ?",
      "Do you stream or other cam sites or only stripchat?  Did you hear about https://bit.ly/high-payout ?",
      "Do you stream or other cam sites?  Did you hear about https://bit.ly/high-payout ?",
      "I really like your hot shows and hope you are making good money here! Did you hear about https://bit.ly/high-payout ?",
      "I really like your hot shows! Are your also on other cam sites?  Did you hear about https://bit.ly/high-payout ?",
      "I really like your PRO shows 🔥🔥🔥 Did you hear about https://bit.ly/high-payout ?",
    ]);

    const textTwitterPro = ref([
      "I like your pro business approach. Did you hear about https://bit.ly/high-payout ?",
      "I really like your 🔥 HOT shows and I see that you are earning on different channels. Did you hear about https://bit.ly/high-payout ?",
      "I really like your hot shows and hope you are making good money here! Did you hear about https://bit.ly/high-payout ?",
      "I really like your PRO shows 🔥🔥🔥 Did you hear about https://bit.ly/high-payout ?",
      "I really like your PRO shows and I see that you are earning on different channels. Did you hear about https://bit.ly/high-payout ?",
      "I see that you are earning on different channels. Did you hear about https://bit.ly/high-payout ?",
      "I see that you are PRO and you are earning on different channels. Did you hear about https://bit.ly/high-payout ?",
      "I see that you are really PRO and you are earning on different channels. Did you hear about https://bit.ly/high-payout ?",
    ]);

    const textStudio = ref([
      "Hi Guys 💣 Do you babes stream also on other cam sites or only stripchat? Did you hear about https://bit.ly/high-payout ?",
    ]);

    // console.log(cam.value.age);

    const getRandomFromArr = function (arrName) {
      if (typeof arrName === "string") {
        return arrName;
      } else {
        const randomed = arrName[Math.trunc(Math.random() * arrName.length)];
        return randomed;
      }
    };

    const choosenHi = ref();
    const choosenIconsAll = ref();
    const choosenTextTwitter = ref();
    const choosenTextTwitterPro = ref();

    const getRandomForPro = () => {
      if (isCamgirlPro.value) return getRandomFromArr(textTwitterPro.value);
      if (!isCamgirlPro.value) return getRandomFromArr(textTwitter.value);
    };

    choosenHi.value = getRandomFromArr(hi.value);
    choosenIconsAll.value = getRandomFromArr(iconsAll.value);
    // choosenTextTwitter.value = getRandomFromArr(textTwitter.value);
    choosenTextTwitter.value = getRandomFromArr(textTwitter.value);
    choosenTextTwitterPro.value = getRandomFromArr(textTwitterPro.value);

    const getChoosenHi = () => (choosenHi.value = getRandomFromArr(hi.value));
    const getChoosenIconsAll = () =>
      (choosenIconsAll.value = getRandomFromArr(iconsAll.value));

    const getChoosenTextTwitter = (option) => {
      if (option == "beginner")
        choosenTextTwitter.value = getRandomFromArr(textTwitter.value);
      if (option == "pro")
        choosenTextTwitterPro.value = getRandomFromArr(textTwitterPro.value);
    };

    const refreshAll = () => {
      getChoosenHi();
      getChoosenIconsAll();
      getChoosenTextTwitter("pro");
      getChoosenTextTwitter("beginner");
    };

    function copyToClipboard(text) {
      var dummy = document.createElement("textarea");
      // to avoid breaking orgain page when copying more words
      // cant copy when adding below this code
      // dummy.style.display = 'none'
      document.body.appendChild(dummy);
      //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    }

    function openInNewTab(url) {
      var win = window.open(url, "_blank");
      win.focus();
    }

    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    const clearName = (name) => {
      let newName = name
        .replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, "")
        .replace(/([A-Z]+)/g, " $1")
        .replace(/([A-Z][a-z])/g, " $1")
        .replace(/[\(\)]+/g, "")
        .replace(/[0-9]/g, " ")
        .replace(/[\.\:\,\_\&\/]+/g, " ")
        .replaceAll("-", " ")
        .replaceAll("@", "")
        .replaceAll(" and ", " ")
        .replace(/\s{2,}/g, " ")
        .toLowerCase()
        .trim()
        .split(" ")
        .map((el) => capitalize(el));

      // console.log("\n");
      // console.log(name);
      // console.log(newName);

      return newName;
    };

    clearedNames.value = clearName(cam.value.name);
    clearedChosenName.value = clearedNames.value[0];

    class Tweet {
      constructor(tweetText) {
        this.tweetText = tweetText;
      }
    }

    const addTwitterText = async (camId, tweetText) => {
      // console.log(camId);
      // console.log(tweetText);
      const tweetItem = new Tweet(tweetText);
      const docRef = doc(db, databaseName.value, camId);
      await updateDoc(docRef, {
        lastTweet: { ...tweetItem, createdAt: serverTimestamp() },
      });
      console.log("Tweet sent");
    };

    const handleGo = (url, tweetText) => {
      console.log("GO!");
      addTwitterText(camId.value, tweetText);
      copyToClipboard(tweetText);
      openInNewTab(`https://${url}`);
    };

    const removeTweet = async (camId) => {
      const docRef = doc(db, databaseName.value, camId);
      await updateDoc(docRef, {
        lastTweet: deleteField(),
      });
      isNotTweetRemoved.value = false;
      console.log("Tweet text removed");
    };

    const changeName = () => {
      let namesArr = clearName(cam.value.name);
      let namesCounter = clearName(cam.value.name).length;

      if (counter.value >= namesCounter - 1) {
        counter.value = 0;
        clearedChosenName.value = namesArr[counter.value];
        // console.log(clearedChosenName.value);
      } else {
        counter.value++;
        clearedChosenName.value = namesArr[counter.value];
        // console.log(clearedChosenName.value);
      }
    };

    const parseDate = (timestamp) => {
      console.log(parse(timestamp, "t", new Date()));
      return formatDistanceToNow(parse(timestamp, "t", new Date()));
    };

    watchEffect(() => {
      // console.log("🔥");
      // console.log(props.isCamgirlPro);
      // console.log("🔥");
      // console.log(isCamgirlPro.value);
      // if (isCamgirlPro.value) refreshAll();
    });

    //TODO zrobic dodawanie opisu do bazy, data/tresc/link // zastawnowic sie czy warto, moze sama data wystarczy / last modified, czy cos takiego tylko ze to powinna byc data wpisania komentarza a nie updatu kamerki w bazie.
    return {
      cam,
      hi,
      iconsAll,
      textTwitter,
      textStudio,
      getRandomFromArr,
      choosenHi,
      choosenIconsAll,
      choosenTextTwitter,
      getChoosenHi,
      getChoosenIconsAll,
      getChoosenTextTwitter,
      refreshAll,
      handleGo,
      capitalize,
      clearName,
      clearedNames,
      removeTweet,
      isNotTweetRemoved,
      clearedChosenName,
      changeName,
      formatDistanceToNow,
      parse,
      parseDate,
      databaseName,
      choosenTextTwitterPro,
    };
  },
};
</script>

<style scoped>
.twitterBox {
  background-color: rgb(0, 60, 255);
  border: 2px solid rgb(0, 60, 255);
  border-radius: 7px;

  padding: 25px;
  font-size: 1.2rem;
  display: flex;
  font-weight: 600;
  height: 80px;
  /* align-items: center; */
  /* position: fixed; */
  bottom: 15px;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  /* flex-basis: 100%; */
  margin-bottom: 5px;
  width: 100%;
}
.text span:hover {
  cursor: pointer;
  /* background-color: #1470ad; */
  background-color: rgb(0, 49, 209);
  border-radius: 4px;
}

.text {
  /* width: 70%; */
  width: 650px;
}
.buttons {
  width: 25%;
  display: flex;
  text-align: right;
  justify-content: flex-end;
  position: relative;
}
.refresh {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
  font-size: 1rem;
  line-height: 39px;
  color: white;
  border: 2px solid white;
  border-radius: 100px;
  background-color: #202124;
  margin: 15px;
}
.refresh:hover {
  cursor: pointer;
}
.material-icons::selection {
  background: #202124; /* WebKit/Blink Browsers */
}
.material-icons::-moz-selection {
  background: #202124; /* Gecko Browsers */
}

.no-shrink {
  flex-shrink: 0;
}
.row {
  width: 100%;
  /* background-color: rgb(0, 60, 255); */
  padding: 10px 25px;
  border-radius: 7px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.tweetText {
  padding-righ: 25px;
}

.pro {
  background-color: #f7630c;
  border-color: #fce100;
  color: #000000;
}
</style>
