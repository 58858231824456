// import { createRouter, createWebHashHistory } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
// import Signup from "../views/Signup.vue";
import Import from "../views/Import.vue";
import ConvertBio from "../views/ConvertBio.vue";

// firebase imports
import { auth } from "../firebase/config";

const requireAuth = (to, from, next) => {
  let user = auth.currentUser;
  if (!user) {
    next({ name: "Login" });
  } else {
    next();
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: requireAuth,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  // {
  //   path: "/signup",
  //   name: "Signup",
  //   component: Signup,
  // },
  {
    path: "/import",
    name: "Import",
    component: Import,
  },
  {
    path: "/convertbio",
    name: "ConvertBio",
    component: ConvertBio,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// const router = createRouter({
//   history: createWebHashHistory(),
//   routes,
// });

export default router;
