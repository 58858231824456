<template>
  <form @submit.prevent="handleSubmit">
    <div class="loginBox">
      <h2>Login</h2>

      <label for="email">Email:</label>
      <input type="email" name="email" v-model="email" required />

      <label for="password">Password:</label>
      <input type="password" name="password" v-model="password" required />

      <button>🔓 Login</button>
      <div v-if="error">{{ error }}</div>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import useLogin from "../composables/useLogin";

export default {
  setup() {
    const { login, error } = useLogin();
    const router = useRouter();

    const email = ref("");
    const password = ref("");

    const handleSubmit = async () => {
      await login(email.value, password.value);

      if (!error.value) {
        router.push("/");
      }
    };

    return { email, password, handleSubmit, error };
  },
};
</script>

<style scoped>
.container {
  max-width: 560px;
  margin: 0 auto;
}
form label,
form input {
  display: block;
}
form input {
  padding: 4px;
  margin-top: 10px;
  margin-bottom: 20px;
}
/* button {
  background: #0ec58e;
  color: #fff;
  border: 0;
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
} */

.loginBox {
  width: 300px;
  /* border: 1px solid rgb(114, 114, 114); */
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
}

.loginBox input {
  border-radius: 4px;
  border: 0px;
  padding: 10px 16px;
  margin: 0px 10px 20px 0px;
  height: 26px;
  border: 1px solid rgb(114, 114, 114);
  background-color: #202124;
  color: white;
}

.loginBox button {
  border-radius: 4px;
  border: 0px;
  padding: 10px 16px;
  margin: 0px 10px 20px 0px;
  height: 46px;
  border: 1px solid rgb(114, 114, 114);
  background-color: #202124;
  color: white;
}

.loginBox button:hover {
  cursor: pointer;
  background-color: #3f3f3f;
  transition-duration: 0.3s;
}

input.getData {
  padding: 0px 5px;
  /* width: 65px; */
}
</style>
