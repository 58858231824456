import { ref, watchEffect } from "vue";

// firebase imports
import { db } from "../firebase/config";
import {
  collection,
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";

const getCollection = (c, ob, l) => {
  const documents = ref(null);

  // collection reference
  let colRef = collection(db, c);

  if (ob && l) {
    colRef = query(colRef, orderBy(ob, "desc"), limit(l));
  } else if (ob) {
    colRef = query(
      colRef,
      // orderBy(ob, "desc"),
      // where("status", "==", "todo"),
      // where("twitter", "!=", false)
      orderBy(ob, "desc"),
      where("status", "==", "todo"),
      where("twitter", "!=", false),
      // where("broadcastGender", "==", "female")
      where("broadcastGender", "in", ["female", "tranny"])
      // where("broadcastGender", ">", "male")
    );
  } else {
    colRef = query(colRef);
  }

  const unsub = onSnapshot(colRef, (snapshot) => {
    let results = [];
    snapshot.docs.forEach((doc) => {
      results.push({ ...doc.data(), id: doc.id });
    });

    // update values
    documents.value = results;
  });

  watchEffect((onInvalidate) => {
    onInvalidate(() => unsub());
  });
  console.log(documents);
  return { documents };
};

export default getCollection;
